document.addEventListener('DOMContentLoaded', function () {
  if (!!document.querySelector('.product-dops')) {

    const productsCheckboxes = document.querySelectorAll('.product-dops__list-checkbox')

    const totalSetup = document.querySelector('.product-dops__prices-item .product-dops__prices-price--setup .price-item')
    let totalSetupNums = totalSetup.textContent.trim()
    let totalSetupPrice = parseInt(totalSetupNums.replace(/\s/g, ''), 10)
    document.querySelector('.product-dops__prices-item .product-dops__prices-price--setup').setAttribute('data-start-price', totalSetupPrice)

    const totalSubscription = document.querySelector('.product-dops__prices-item .product-dops__prices-price--subscription .price-item')
    let totalSubscriptionNums = ''
    let totalSubscriptionPrice = 0
    if (!!totalSubscription) {
      totalSubscriptionNums = totalSubscription.textContent.trim()
      totalSubscriptionPrice = parseInt(totalSubscriptionNums.replace(/\s/g, ''), 10)
      document.querySelector('.product-dops__prices-item .product-dops__prices-price--subscription').setAttribute('data-start-price', totalSubscriptionPrice)
    }

    productsCheckboxes.forEach(checkbox => {
      checkbox.addEventListener('change', function () {
        let checkboxSetup = this.closest('.product-dops__list-item').querySelector('.product-dops__list-price--setup .price-item')
        let checkboxSetupPrice = 0
        if (!!checkboxSetup) {
          let checkboxSetupNums = checkboxSetup.textContent.trim()
          checkboxSetupPrice = parseInt(checkboxSetupNums.replace(/\s/g, ''), 10)
        }

        let checkboxSubscription = this.closest('.product-dops__list-item').querySelector('.product-dops__list-price--subscription .price-item')
        let checkboxSubscriptionPrice = 0
        if (!!checkboxSubscription) {
          let checkboxSubscriptionNums = checkboxSubscription.textContent.trim()
          checkboxSubscriptionPrice = parseInt(checkboxSubscriptionNums.replace(/\s/g, ''), 10)
        }

        if (this.checked) {
          totalSetupPrice += checkboxSetupPrice
          if (!!totalSubscription) {
            totalSubscriptionPrice += checkboxSubscriptionPrice;
          }
        } else {
          totalSetupPrice -= checkboxSetupPrice;
          if (!!totalSubscription) {
            totalSubscriptionPrice -= checkboxSubscriptionPrice;
          }
        }

        totalSetup.textContent = totalSetupPrice.toLocaleString('ru-RU');
        if (!!totalSubscription) {
          totalSubscription.textContent = totalSubscriptionPrice.toLocaleString('ru-RU');
        }

      })
    })

  }
})