
// Находим элементы кнопки и меню
const accordions = document.querySelectorAll('.faq-item')

for (let i = 0; i < accordions.length; i++) {
	accordions[i].addEventListener('click', function () {
		this.classList.toggle('faq-item--active')
		let panel = this.querySelector('.faq-answer')
		if (panel.style.maxHeight) {
			panel.style.maxHeight = null
			panel.style.marginTop = null
			panel.style.marginBottom = null
		} else {
			panel.style.maxHeight = panel.scrollHeight + 'px'
			panel.style.marginTop = '20px'
			panel.style.marginBottom = '20px'
		}
	})
}

// Находим элементы кнопки и меню
const accordionHeader = document.querySelectorAll('.header-bottom-item--more')

for (let i = 0; i < accordions.length; i++) {
	accordions[i].addEventListener('click', function () {
		this.classList.toggle('header-bottom-item--more-active')
		let panel = this.querySelector('.header-bottom-sublist')
		if (panel.style.maxHeight) {
			panel.style.maxHeight = null
			panel.style.marginTop = null
			panel.style.marginBottom = null
		} else {
			panel.style.maxHeight = panel.scrollHeight + 'px'
			panel.style.marginTop = '20px'
			panel.style.marginBottom = '20px'
		}
	})
}
