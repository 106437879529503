export const dataSearchLocal = [
  {
    weight: 1,
    category_weight: 10,
    category: "Услуги",
    tag: "Охрана квартир",
    link: "/ohrana-kvartir/",
    queries: ["Охрана квартир", "Охрана", "Квартиры", "AJAX", "ФОФЧ", "Всякое"],
  },
  {
    weight: 9,
    category_weight: 10,
    category: "Услуги",
    tag: "Охрана коттеджей",
    link: "/ohrana-kottedjej/",
    queries: ["Охрана коттеджей", "Охрана дома", "Охрана", "Коттеджи", "Дом", "DELTA", "ajax"],
  },
  {
    weight: 9,
    category_weight: 10,
    category: "Услуги",
    tag: "Охрана коттеджей",
    link: "/ohrana-kottedjej/",
    queries: ["cute 2", "cute", "Охрана", "Коттеджи", "Дом", "DELTA", "ajax"],
  },
  // {
  //   weight: 3,
  //   category_weight: 10,
  //   category: "Услуги",
  //   tag: "Охрана бизнеса",
  //   link: "/ohrana-biznesa/",
  //   queries: ["Охрана бизнеса", "Охрана", "Бизнес"],
  // },
  {
    weight: 4,
    category_weight: 8,
    category: "Блог",
    tag: "Что охранять?",
    link: "/blog/",
    queries: ["чоп", "Охрана", "Порядок"],
  },
  {
    weight: 2,
    category_weight: 8,
    category: "Блог",
    tag: "Кого охранять?",
    link: "/blog/",
    queries: ["чоп", "кого", "Порядок", "кек"],
  },
  // {
  //   weight: 2,
  //   category_weight: 8,
  //   category: "Блог",
  //   tag: "Б Кого охранять?",
  //   link: "/blog/",
  //   queries: ["чоп", "кого", "Порядок", "кек"],
  // },
  // {
  //   weight: 2,
  //   category_weight: 8,
  //   category: "Блог",
  //   tag: "п Кого охранять?",
  //   link: "/blog/",
  //   queries: ["чоп", "кого", "Порядок", "кек"],
  // },
  // {
  //   weight: 3,
  //   category_weight: 10,
  //   category: "Услуги",
  //   tag: "Охрана бизнеса",
  //   link: "/ohrana-biznesa/",
  //   queries: ["Охрана бизнеса", "Охрана", "Бизнес"],
  // },
  // {
  //   weight: 3,
  //   category_weight: 8,
  //   category: "Блог",
  //   tag: "О Как охранять?",
  //   link: "/blog/",
  //   queries: ["чоп", "как", "Порядок", "кек"],
  // },
  // {
  //   weight: 3,
  //   category_weight: 8,
  //   category: "Блог",
  //   tag: "О Как охранять?",
  //   link: "/blog/",
  //   queries: ["чоп", "как", "Порядок", "кек"],
  // },
  // {
  //   weight: 3,
  //   category_weight: 8,
  //   category: "Блог",
  //   tag: "О Как охранять?",
  //   link: "/blog/",
  //   queries: ["чоп", "как", "Порядок", "кек"],
  // },
  // {
  //   weight: 3,
  //   category_weight: 8,
  //   category: "Блог",
  //   tag: "О Как охранять?",
  //   link: "/blog/",
  //   queries: ["чоп", "как", "Порядок", "кек"],
  // },
  // {
  //   weight: 3,
  //   category_weight: 8,
  //   category: "Блог",
  //   tag: "О Как охранять?",
  //   link: "/blog/",
  //   queries: ["чоп", "как", "Порядок", "кек"],
  // },
  // {
  //   weight: 3,
  //   category_weight: 8,
  //   category: "Блог",
  //   tag: "О Как охранять?",
  //   link: "/blog/",
  //   queries: ["чоп", "как", "Порядок", "кек"],
  // },
  // {
  //   weight: 10,
  //   category_weight: 9,
  //   category: "Акции",
  //   tag: "Выгода на охрану",
  //   link: "/stock/",
  //   queries: ["Выгода", "Охрана со скидкой", "Акция"],
  // },
  // {
  //   weight: 3,
  //   category_weight: 8,
  //   category: "Блог",
  //   tag: "О Как охранять?",
  //   link: "/blog/",
  //   queries: ["чоп", "как", "Порядок", "кек"],
  // },
  // {
  //   weight: 3,
  //   category_weight: 8,
  //   category: "Блог",
  //   tag: "О Как охранять?",
  //   link: "/blog/",
  //   queries: ["чоп", "как", "Порядок", "кек"],
  // },
];

export const keyboardLayoutLocal = {
  'q': 'й',
  'w': 'ц',
  'e': 'у',
  'r': 'к',
  't': 'е',
  'y': 'н',
  'u': 'г',
  'i': 'ш',
  'o': 'щ',
  'p': 'з',
  '[': 'х',
  ']': 'ъ',
  'a': 'ф',
  's': 'ы',
  'd': 'в',
  'f': 'а',
  'g': 'п',
  'h': 'р',
  'j': 'о',
  'k': 'л',
  'l': 'д',
  ';': 'ж',
  '\'': 'э',
  'z': 'я',
  'x': 'ч',
  'c': 'с',
  'v': 'м',
  'b': 'и',
  'n': 'т',
  'm': 'ь',
  ',': 'б',
  '.': 'ю'
};

export const valueSearchReservedLocal = [
  "ajax",
  "gps",
  "gsm",
  "logistics",
  "systems",
  "cute",
  "cute 2",
  "ip",
  "bullet",
  "dome",
  "email",
  "delta",
];
