import Cookies from 'js-cookie'

document.addEventListener('DOMContentLoaded', () => {
  const cookiesMessage = document.querySelector('.cookies-message')
  if (Cookies.get('cookiesConfidence') !== 'confidenceAccepted') {
      setTimeout(() => {
        cookiesMessage.classList.remove('cookies-message--hidden')
      }, 3000);
  }
  
  document.querySelector('.cookies-message__btn').addEventListener('click', function() {
    cookiesMessage.classList.add('cookies-message--hidden');
    Cookies.set('cookiesConfidence', 'confidenceAccepted', { expires: 365, path: '/' });
  })
})