document.addEventListener('DOMContentLoaded', () => {
  // выбор Региона
  dropdown = document.querySelector('.dropdown')
  dropdownBlock = document.querySelector('.dropdown__block')
  dropdownChoose = document.querySelector('.dropdown__choose')
  
  dropdown.addEventListener('click', function() {
    this.classList.toggle('dropdown--active')
    dropdownBlock.classList.toggle('dropdown__block--visible')
  })
  
  document.addEventListener("click", function(event) {
    let targetElement = event.target;
    if (dropdownBlock.classList.contains('dropdown__block--visible')) {
      if (targetElement !== dropdownBlock 
        && targetElement !== dropdown 
        && targetElement !== dropdownChoose) {
        dropdownBlock.classList.remove('dropdown__block--visible')
        dropdown.classList.toggle('dropdown--active')
      }
    }
  });
})