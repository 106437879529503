document.addEventListener('DOMContentLoaded', function() {
  const anchorInUrl = window.location.href.indexOf('#');
  
  if (anchorInUrl !== -1) {
      const hash = window.location.href.substring(anchorInUrl + 1);
      const targetElement = document.querySelector('[data-anchor="' + hash + '"]');
      
      if (targetElement) {
          targetElement.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
          });
      }
  }
});
