if (!document.querySelector('.index-first')) {
  document.querySelector('.header').classList.add('header--alt')
}

document.addEventListener('DOMContentLoaded', () => {

  // Отключение ссылки на текущую страницу
  let path = document.location.pathname;
  let thisPageLink = document.querySelectorAll('a[href="' + path + '"]');

  if (thisPageLink.length > 0) {
    thisPageLink.forEach(function(link) {
      link.setAttribute('href', 'javascript:void(0)');
      link.classList.add('pointer-event--none');
    })
  }

  window.addEventListener('scroll', function() {
    const headerElement = document.querySelector('.header');
    const scrollPosition = window.scrollY;

    if (scrollPosition > 150) {
      headerElement.classList.add('header--fixed');
    } else {
      headerElement.classList.remove('header--fixed');
    }
  });

  // // Переключение свичей бля чб комплекта
  // let switchComplect = document.querySelectorAll('.product-content__switch-item')
  // let complectImage = document.querySelector('.product-content__image img')

  // if (!!switchComplect) {
  //   switchComplect.forEach(function(elem) {
  //     elem.addEventListener('click', function() {
  //       let attrDataImage = this.getAttribute('data-image')
  //       complectImage.setAttribute('src', attrDataImage)
  //       this.classList.add('product-content__switch-item--active')
        
  //       let allSiblings = getAllSiblings(this)
  //       allSiblings.forEach(function(elem) {
  //         elem.classList.remove('product-content__switch-item--active')
  //       })
  //     })
  //   })
  // }

  function getAllSiblings(element) {
    const siblings = [];
    let sibling = element.parentNode.firstChild;
    
    while (sibling) {
        if (sibling.nodeType === 1 && sibling !== element) {
            siblings.push(sibling);
        }
        sibling = sibling.nextSibling;
    }
    
    return siblings;
  }


  if (document.getElementById('menuButton')) {
    const header = document.querySelector('.header')

    document.getElementById('menuButton').addEventListener('click', function () {
      this.classList.toggle('menu-button--cross')
      header.classList.toggle('header--open')
      document.querySelector('html').classList.toggle('no-scroll')
    });
  }

  if (window.innerWidth < 980) {
    document.querySelector('.header-bottom-item--more').addEventListener('click', function () {
      this.classList.toggle('header-bottom-item--more-active')
      this.querySelector('.header-bottom-sublist').classList.toggle('header-bottom-sublist--active')
    })
  } else {
    document.querySelector('.header-bottom-item--more').addEventListener('mouseover', function () {
      this.querySelector('.header-bottom-sublist').classList.add('header-bottom-sublist--active')
    })
    document.querySelector('.header-bottom-item--more').addEventListener('mouseout', function () {
      this.querySelector('.header-bottom-sublist').classList.remove('header-bottom-sublist--active')
    })
  }

  // Открытие плашки в физ охране
  const plusElement = document.querySelectorAll('.pz-decisions__slider-plus')
  plusElement.forEach(function (elem) {
    elem.addEventListener('click', function () {
      this.closest('.pz-decisions__slider-img').classList.toggle('pz-decisions__slider-img--show')
    })
  })

  const appElements = document.querySelectorAll('.index-app__features-item')

  if (!!appElements) {
    appElements.forEach(function (elem) {
      elem.addEventListener('click', function() {
        elem.classList.toggle('index-app__features-item--active')
      })
    })
  }

  // Вскрывашки с текстом в физ охране
  const smartHouseBlock = document.querySelectorAll('.smart-house__block')
  smartHouseBlock.forEach(function(elem) {
    elem.addEventListener('click', function() {
      this.classList.toggle('smart-house__block--active')
    })
  })

  const contactsShowLink = document.querySelectorAll('[data-show]')
  if (!!contactsShowLink) {
    const contactsShowElement = document.querySelectorAll('.contacts-block__info-text')

    contactsShowLink.forEach(function(elem) {
      elem.addEventListener('click', function() {
        let attrElem = this.getAttribute('data-show')
        contactsShowElement.forEach(function(elem) {
          elem.classList.remove('contacts-block__info-text--active')
        })

        document.querySelector('.contacts-block__info-text--' + attrElem).classList.add('contacts-block__info-text--active')
      })
    })

    const contactsShowElementCross = document.querySelectorAll('.contacts-block__info-text-cross')
    contactsShowElementCross.forEach(function(elem) {
      elem.addEventListener('click', function() {
        this.closest('.contacts-block__info-text').classList.remove('contacts-block__info-text--active')
      })
    })
  }

  // Перенос допников в разметку left блока
  if(document.querySelector('.product-dops')) {
      const productDopsElement = document.querySelector('.product-dops');
      const productLeftBlock = document.querySelector('.product-content--left');
      const productRightBlock = document.querySelector('.product-content--right');
      const productDops = document.querySelector('.product-dops');
      // const productDopsMore = document.querySelector('.product-dops__more');
      // const productDopsList = document.querySelector('.product-dops__list');
      const hexaBlock = document.querySelector('.product-content__features--hexagray');
    
      function moveDopsInsideLeft() {
        productLeftBlock.insertBefore(productDops, hexaBlock.nextSibling);
      }
    
      function moveDopsBack() {
        productRightBlock.appendChild(productDops);
      }

      function handleResizeDops() {
        if (window.innerWidth <= 1200 && !productLeftBlock.classList.contains('js-moved')) {
          moveDopsInsideLeft();
          productLeftBlock.classList.add('js-moved');

          productDopsElement.classList.remove('product-dops--sticky')
        } else if (window.innerWidth > 1200 && productLeftBlock.classList.contains('js-moved')) {
          moveDopsBack();
          productLeftBlock.classList.remove('js-moved');

          productDopsElement.classList.add('product-dops--sticky')
        }
      }
    
      window.addEventListener('resize', handleResizeDops);
    
      // Проверяем при загрузке страницы
      handleResizeDops();
  }
});