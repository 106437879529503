document.addEventListener('DOMContentLoaded', function() {
  let modalStoreTriggers = document.querySelectorAll('[data-modal="playstore--modal"]');
  let modalStore = document.querySelector('.modal-store');
  let modalStoreClose = document.querySelector('.modal-store__cross');

  if (modalStoreTriggers) {
    modalStoreTriggers.forEach(element => {
      element.addEventListener('click', function (event) {
        event.preventDefault()
        document.querySelector('html').classList.add('no-scroll')
        modalStore.classList.add('is-visible')
      })
    });
  }

  modalStoreClose.addEventListener('click', function() {
    document.querySelector('html').classList.remove('no-scroll')
    modalStore.classList.remove('is-visible')
  })


  if (modalStore) {
    modalStore.addEventListener('click', function (event) {
  
      if (event.target.classList.contains('icon-base--cross')
          || event.target.classList.contains('icon-base__use--scross')
          || event.target.classList.contains('modal-store__cross')
          || event.target.classList.contains('modal-store')) {
        event.preventDefault()

        this.classList.remove('is-visible')
        document.querySelector('html').classList.remove('no-scroll')
      }
    }) 
  }
});
