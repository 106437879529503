document.addEventListener('DOMContentLoaded', () => {
  // выбор Региона
  let btnTop = document.querySelector('.btn-top')
  let btnTopVisible = false;
  let windowHeight = window.innerHeight
  var startPage = document.querySelector('body');

  btnTop.addEventListener('click', function() {
    startPage.scrollIntoView({ behavior: 'smooth' });
  });

  window.addEventListener('scroll', function() {
    if (window.scrollY > windowHeight && !btnTopVisible) {
      btnTop.classList.add('btn-top--visible');
      btnTopVisible = true;
    } else if (window.scrollY <= windowHeight && btnTopVisible) {
      btnTop.classList.remove('btn-top--visible');
      btnTopVisible = false;
    }
  });
})