document.addEventListener('DOMContentLoaded', () => {

  // Открытие всплывашки при клике на блок hexa-features--gray
  const block1Items = document.querySelectorAll('.hexa-features--gray .hexa-features__item');
  const block2Items = document.querySelectorAll('.hexa-features--gray .hexa-features__info');
  // Добавляем обработчик событий к каждому элементу в первом блоке
  block1Items.forEach(function (item, index) {
    item.addEventListener('click', function () {
      // Получаем элемент второго блока с тем же индексом и меняем его цвет
      block2Items[index].classList.remove('hexa-features__info--hide');
      block2Items[index].classList.add('hexa-features__info--show');
    });
  });

  // Открытие всплывашки при клике на блок product-content__type-work
  const block1ItemsTypesWork = document.querySelectorAll('.product-content__type-work .hexa-features__item');
  const block2ItemsTypesWork = document.querySelectorAll('.product-content__type-work .hexa-features__info');
  // Добавляем обработчик событий к каждому элементу в первом блоке
  block1ItemsTypesWork.forEach(function (item, index) {
    item.addEventListener('click', function () {
      // Получаем элемент второго блока с тем же индексом и меняем его цвет
      block2ItemsTypesWork[index].classList.remove('hexa-features__info--hide');
      block2ItemsTypesWork[index].classList.add('hexa-features__info--show');
    });
  });

  // Открытие всплывашки при клике на блок index-defend
  const block1ItemsDefend = document.querySelectorAll('.index-defend .hexa-features__item');
  const block2ItemsDefend = document.querySelectorAll('.index-defend .hexa-features__info');
  // Добавляем обработчик событий к каждому элементу в первом блоке
  block1ItemsDefend.forEach(function (item, index) {
    item.addEventListener('click', function () {
      // Получаем элемент второго блока с тем же индексом и меняем его цвет
      block2ItemsDefend[index].classList.remove('hexa-features__info--hide');
      block2ItemsDefend[index].classList.add('hexa-features__info--show');
    });
  });

  // Открытие всплывашки при клике на блок product-content__tech-skud
  const block1ItemsSkud = document.querySelectorAll('.product-content__tech-skud .hexa-features__item');
  const block2ItemsSkud = document.querySelectorAll('.product-content__tech-skud .hexa-features__info');
  // Добавляем обработчик событий к каждому элементу в первом блоке
  block1ItemsSkud.forEach(function (item, index) {
    item.addEventListener('click', function () {
      let element = block2ItemsSkud[index];
      let parent = element.parentNode;
      let siblings = parent.children;


      for (var i = 0; i < siblings.length; i++) {
        if (siblings[i] !== element) {
          console.log(siblings[i]);
          siblings[i].classList.remove('hexa-features__info--show');
          siblings[i].classList.add('hexa-features__info--hide');
        }
      }

      // Получаем элемент второго блока с тем же индексом и меняем его цвет
      block2ItemsSkud[index].classList.remove('hexa-features__info--hide');
      block2ItemsSkud[index].classList.add('hexa-features__info--show');
    });
  });

  // Скрытие всплывашки в блоке с текстом
  if (document.querySelector('.hexa-features .hexa-features__info-cross')) {
    document.querySelectorAll('.hexa-features__info-cross').forEach(function (elem) {
      elem.addEventListener('click', function () {
        this.closest('.hexa-features__info').classList.remove('hexa-features__info--show')
        this.closest('.hexa-features__info').classList.add('hexa-features__info--hide')
      })
    })
  }
})