import {dataSearchLocal, keyboardLayoutLocal, valueSearchReservedLocal} from './search-data';

window.addEventListener("DOMContentLoaded", () => {
  // Получаение куки по ключу
  function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }
  const csrftoken = getCookie('csrftoken');

  const searchButtonWrapper = document.querySelector('.search-btn.d-none');
  let dataSearch = [];  
  // let dataSearch = dataSearchLocal.sort((a, b) => a.category.localeCompare(b.category));;
  let keyboardLayout = keyboardLayoutLocal;

  const urlHost = window.location.origin

  let getSearchData = function() {
    fetch(urlHost + '/search/')
    .then(response => response.json())
    .then(data => {
      // сортируем список по категории, чтобы они не путались при их размещении в разметку
      data.sort((a, b) => a.category__name.localeCompare(b.category__name))
      dataSearch = data
      searchButtonWrapper.classList.remove('d-none')
    })
    .catch(error => {
      return
    })
  }
  getSearchData()

  let postSearchData = function(queries) {
    const url = urlHost + '/search/'

    let queriesString = queries.join(', ')
    const data = {
      "query" : queriesString
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken,
      },
      body: JSON.stringify(data)
    };

    fetch(url, requestOptions)
      .then(response => response.json())
      // .then(data => console.log(data))
      // .catch(error => console.log(error));
  }
  
  let resultLists;
  const searchButton = document.querySelector('#search-btn');
  const searchRowResultWrapper = document.querySelector('.search-row-result--wrapper')
  const searchInput = document.querySelector('.search-input')
  const searchMessage = document.querySelector('.search-message')
  const searchClear = document.querySelector('.search-clear')
  const searchFormWrapper = document.querySelector('.search-form-wrapper')

  // Массив для генерации не повторяющихся категорий
  let searchCategories = []

  // Функция передачи верстки и данных из dataSearch в нее
  let appendSearchRow = function(data) {

    for (let i = 0; i < data.length; i++) {
      const searchRowResult = `<div class="search-row-result" data-cat-w="${data[i]["category__weight"]}"></div>`;
      const searchRowResultList = `<ul class="search-row-result-list"></ul>`;
      const searchRowResultTitle = `<div class="search-row-result-title">${data[i]["category__name"] + ":"}</div>`;
      const searchRowResultListItem = `
      <li class="search-row-result-list-item" data-w="${data[i]["weight"]}">
        <a data-search-types="${data[i]["queries"].toString().toLowerCase()}" href="${data[i]["link"]}" class="search-row-result-list-item-link">${data[i]["tag"]}</a>
      </li>`;

      if (!searchCategories.includes(data[i]["category__name"])) {
        searchCategories.push(data[i]["category__name"]);
        searchRowResultWrapper.insertAdjacentHTML('afterbegin', searchRowResult)
        searchRowResultWrapper.querySelector('.search-row-result').insertAdjacentHTML('afterbegin', searchRowResultTitle)
        searchRowResultWrapper.querySelector('.search-row-result').insertAdjacentHTML('beforeend', searchRowResultList)
        searchRowResultWrapper.querySelector('.search-row-result-list').insertAdjacentHTML('afterbegin', searchRowResultListItem)
      } else {
        searchRowResultWrapper.querySelector('.search-row-result-list').insertAdjacentHTML('beforeend', searchRowResultListItem)
      }
    }

    // Обнуляем массив, чтобы не генерить лишнюю верстку при повторном вызове поиска
    searchCategories = []
    // Переворачиваем список выдачи в обратном порядке:
    document.querySelectorAll('.search-row-result--wrapper > .search-row-result').forEach(n => n.parentNode.prepend(n));
    resultLists = document.querySelectorAll('.search-row-result-list');
  }

  // Функция для замены английских символов на русские
  let translate = function(inputValue) {
    const searchLinkItem = document.querySelectorAll('.search-row-result-list-item-link');
    let translatedValue = '';
    
    for (let i = 0; i < inputValue.length; i++) {
      const character = inputValue.charAt(i).toLowerCase();
      const translatedCharacter = keyboardLayout[character];
      translatedValue += translatedCharacter || character;
    }

    if (valueSearchReservedLocal.includes(inputValue)) {
      toggleDisplayRowResult(searchLinkItem, inputValue)
    } else {
      toggleDisplayRowResult(searchLinkItem, translatedValue)
    }
  }

  let sortLinks = function() {
    let resultListBlocks = Array.from(searchRowResultWrapper.querySelectorAll('.search-row-result-list'));
    resultListBlocks.forEach(function(resultList) {
      let listItemElements = Array.from(resultList.querySelectorAll('.search-row-result-list-item'));

      listItemElements.sort(function(a, b) {
        let wA = parseInt(a.getAttribute('data-w'));
        let wB = parseInt(b.getAttribute('data-w'));
        let nameA = a.innerText.toLowerCase();
        let nameB = b.innerText.toLowerCase();

        // Сортируем по data-w, чем больше тем первее
        if (wA > wB) {
          return -1;
        } else if (wA < wB) {
          return 1;
        } else {
          // Если значения data-w одинаковые, сортируем по алфавиту
          if (nameA < nameB) {
            return -1;
          } else if (nameA > nameB) {
            return 1;
          } else {
            return 0;
          }
        }
      });

      listItemElements.forEach(function(item) {
        resultList.appendChild(item); // Перемещаем элементы обратно в текущий блок "search-row-result-list" в новом порядке
      });
    });
  }

  let sortCategories = function() {
    let resultRowBlocks = Array.from(searchRowResultWrapper.querySelectorAll('.search-row-result'));

    resultRowBlocks.sort(function(a, b) {
      let wA = parseInt(a.getAttribute('data-cat-w'));
      let wB = parseInt(b.getAttribute('data-cat-w'));
      let nameA = a.innerText.toLowerCase();
      let nameB = b.innerText.toLowerCase();

      // Сортируем по data-w, чем больше тем первее
      if (wA > wB) {
        return -1;
      } else if (wA < wB) {
        return 1;
      } else {
        // Если значения data-w одинаковые, сортируем по алфавиту
        if (nameA < nameB) {
          return -1;
        } else if (nameA > nameB) {
          return 1;
        } else {
          return 0;
        }
      }
    });

    resultRowBlocks.forEach(function(item) {
      searchRowResultWrapper.appendChild(item); // Перемещаем элементы обратно в текущий блок "search-row-result-list" в новом порядке
    });
  }

  let addMoreBtnForResultList = function(resultLists) {
    if (resultLists.length > 1) {
      // перебираем все списки search-row-result-list
      resultLists.forEach(function(resultList) {
        let resultItems = resultList.querySelectorAll('.search-row-result-list-item');
        let resultItemsWidth = 0; // переменная для хранения суммы ширины блоков search-row-result-list-item
      
        // перебираем все блоки search-row-result-list-item и суммируем их ширины
        resultItems.forEach(function(resultItem) {
          resultItemsWidth += resultItem.offsetWidth; // добавляем ширину каждого блока search-row-result-list-item
          // проверяем, что сумма ширины блоков search-row-result-list-item больше ширины текущего списка search-row-result-list
          if (resultItemsWidth > resultList.offsetWidth) {
            resultItem.classList.add('d-none');
  
            if (!resultList.classList.contains('search-row-result-list-more')) {
              resultList.classList.add('search-row-result-list-more');
            }
          }
        });
      });
    } else {

      let resultItems = resultLists.querySelectorAll('.search-row-result-list-item');
      let resultItemsWidth = 0; // переменная для хранения суммы ширины блоков search-row-result-list-item
    
      // перебираем все блоки search-row-result-list-item и суммируем их ширины
      resultItems.forEach(function(resultItem) {
        resultItemsWidth += resultItem.offsetWidth; // добавляем ширину каждого блока search-row-result-list-item
        // проверяем, что сумма ширины блоков search-row-result-list-item больше ширины текущего списка search-row-result-list
        if (resultItemsWidth > resultLists.offsetWidth) {
          resultItem.classList.add('d-none');

          if (!resultLists.classList.contains('search-row-result-list-more')) {
            resultLists.classList.add('search-row-result-list-more');
          }
        }
      });
    }

  }

  let addUsageForMoreBtn = function() {
    let resultLists = document.querySelectorAll('.search-row-result-list');

    resultLists.forEach(function(item) {
      item.addEventListener('click', function(event) {
        // Проверяем, соответствует ли цель события определенному селектору
        if (event.target.matches('.search-row-result-list-more')) {
          if (item.classList.contains('search-row-result-list-more--active')) {
            item.classList.remove('search-row-result-list-more--active')
            addMoreBtnForResultList(this)
          } else {
            item.classList.add('search-row-result-list-more--active')
            item.querySelectorAll('.d-none').forEach(function (block) {
              block.classList.remove('d-none')
            })
          }
        }
      });

    })
  }

  let unRelevantQueries = []

  // оставляем только уникальные нерелевантные запросы без повторений и вложененных значений
  let uniqueUnRelevantQueries = function(array) {
    const onlyUniqueUnRelevantQueries = array.filter((value, index) => {
      return array.every((otherValue, otherIndex) => {
        return index === otherIndex || !otherValue.includes(value);
      });
    });

    return onlyUniqueUnRelevantQueries
  }
  
  // Ввод
  searchInput.addEventListener('input', function(event) {
    const searchTerm = this.value.trim().toLowerCase();
    const searchLinkItem = document.querySelectorAll('.search-row-result-list-item-link');
    const searchMoreBtns = document.querySelectorAll('.search-row-result-list-more');
    const englishRegex = /[a-zA-Z]/;

    if (searchTerm != '') {
      if(englishRegex.test(searchTerm)) {
        translate(searchTerm)
      } else {
        toggleDisplayRowResult(searchLinkItem, searchTerm)
      }
      
      // Скрываем кнопку Еще
      if(searchMoreBtns) {
        searchMoreBtns.forEach(function(item) {
          item.classList.remove('search-row-result-list-more')
          item.classList.remove('search-row-result-list-more--active')
        })
      }

      // показываем кнопку очистки
      searchClear.classList.remove('d-none')

      // Сообщение под input если в них нет активных строк
      let searchItemAll = searchRowResultWrapper.querySelectorAll('.search-row-result')
      const searchItemAllNoDisplay = [...searchItemAll].every(child => child.classList.contains('d-none'));

      if (searchItemAllNoDisplay) {
        searchMessage.textContent = 'По вашему запросу ничего не найдено. Попробуйте написать что-то другое.'
        // отправляет post запрос при обнаружении нерелевантного запроса
        const valueSearch = event.target.value;
        if (!unRelevantQueries.includes(valueSearch)) {
          unRelevantQueries.push(valueSearch);
        }

        // показываем форму с небольшой задержкой и анимацией
        function addDnoneForWrapper() {
          searchFormWrapper.classList.remove('d-none')
          setTimeout(animatedActivateForWrapper, 1000);
        }
        function animatedActivateForWrapper() {
          if(!searchFormWrapper.classList.contains('d-none')) {
            searchFormWrapper.classList.add('search-form-wrapper--active')
          }
        }
        addDnoneForWrapper();
      } else {
        searchMessage.textContent = 'Ваш запрос найден на страницах:'

        // Моментально скрываем форму
        searchFormWrapper.classList.remove('search-form-wrapper--active')
        searchFormWrapper.classList.add('d-none')
      }
    } else if (searchTerm == '') {
      // скрываем кнопку очистки
      toggleDisplayRowResult(searchLinkItem, searchTerm)
      searchMessage.textContent = 'Рекомендуемые страницы:'
      searchLinkItem.forEach(item => item.closest('.search-row-result-list-item').classList.remove('d-none'))
      addMoreBtnForResultList(resultLists)

        // Моментально скрываем форму
        searchFormWrapper.classList.remove('search-form-wrapper--active')
        searchFormWrapper.classList.add('d-none')
    }
  });

  let clearInputValueEvent = function() {
    searchInput.value = ''

    // Создаем новое событие input
    const eventInput = new Event('input');
    searchInput.dispatchEvent(eventInput)
  }

  // Добавляем обработчик события клика на кнопку
  searchClear.addEventListener('click', function() {
    clearInputValueEvent()
  });

  let toggleDisplayRowResult = function(searchLinkItem, searchTerm) {
    // Скрываем и показываем Ссылки в атрибуте которых есть совпадение из поиска
    searchLinkItem.forEach(item => {
      let linkVals = item.getAttribute('data-search-types')
      let listItem = item.closest('.search-row-result-list-item')
      let rowResult = item.closest('.search-row-result')

      if (linkVals.indexOf(searchTerm) != -1) {
        listItem.classList.remove('d-none')
      } else {
        listItem.classList.add('d-none')
      }

      // Скрытие строк если в них нет активных ссылок
      let searchLinkItemAll = Array.from(item.closest('.search-row-result-list-item').parentNode.children);
      const searchLinkItemAllNoDisplay = [...searchLinkItemAll].every(child => child.classList.contains('d-none'));

      if (searchLinkItemAllNoDisplay) {
        rowResult.classList.add('d-none')
      } else {
        rowResult.classList.remove('d-none')
      }
    })
  }

  // Создаем функцию-обработчик события клика
  let clickOnSearch = function() {
    appendSearchRow(dataSearch);
    sortCategories()
    sortLinks()

    searchButton.removeEventListener('click', clickOnSearch);
  }

  searchButton.addEventListener('click', clickOnSearch);
  searchButton.addEventListener('click', function() {
    searchOnSite.classList.add('show')
    document.querySelector('html').classList.add('no-scroll')
  });


  // Получаем модалку поиска, к которому будет добавлен класс "show"
  const searchOnSite = document.querySelector('#searchOnSite');

  searchOnSite.querySelector('.modal__cross.close').addEventListener('click', function() {
    searchOnSite.classList.remove('show')
    document.querySelector('html').classList.remove('no-scroll')
  })

  // Создаем новый экземпляр MutationObserver для отслеживания отображения поиска
  let observerSearchShow = new MutationObserver(function(mutations) {
    mutations.forEach(function(mutation) {
      if (mutation.attributeName === 'class' && searchOnSite.classList.contains('show')) {
        addMoreBtnForResultList(resultLists)
        addUsageForMoreBtn()
        
        observerSearchShow.disconnect()
      }
    });
  });

  // Создаем новый экземпляр MutationObserver для отслеживания отображения поиска
  let observerSearchShowTwo = new MutationObserver(function(mutations) {
    mutations.forEach(function(mutation) {
      if (mutation.attributeName === 'class' && searchOnSite.classList.contains('show')) {
        searchInput.focus()
      }
    });
  });
  
  // массив отправленных нерелевантных запросов
  let sendedArray = []
  // функция для сравнения 2х объектов
  function areObjectsEqual(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
  
    if (keys1.length !== keys2.length) {
      return false;
    }
  
    for (let key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
  
    return true;
  }

  // Создаем новый экземпляр MutationObserver для отслеживания скрытия поиска
  let observerSearchHide = new MutationObserver(function(mutations) {
    mutations.forEach(function(mutation) {
      if (mutation.attributeName === 'class' && !searchOnSite.classList.contains('show')) {
        // отправляем постом данные полученные из массива нерелевантных запросов
        if (uniqueUnRelevantQueries(unRelevantQueries).length > 10) {
          if (areObjectsEqual(sendedArray, uniqueUnRelevantQueries(unRelevantQueries).slice(0, 10))) {
            return
          } else {
            postSearchData(uniqueUnRelevantQueries(unRelevantQueries).slice(0, 10));
            sendedArray = uniqueUnRelevantQueries(unRelevantQueries).slice(0, 10);
          }
        } else {
          if (areObjectsEqual(sendedArray, uniqueUnRelevantQueries(unRelevantQueries))) {
            return
          } else {
            postSearchData(uniqueUnRelevantQueries(unRelevantQueries))
            sendedArray = uniqueUnRelevantQueries(unRelevantQueries);
          }
        }
        // очищаем массив нерелевантных значений
        // unRelevantQueries = []
      }
    });
  });

  observerSearchShowTwo.observe(searchOnSite, { attributes: true });
  observerSearchShow.observe(searchOnSite, { attributes: true });
  observerSearchHide.observe(searchOnSite, { attributes: true });

  searchOnSite.querySelector('button.close').addEventListener('click', function(e) {
    clearInputValueEvent()
    document.querySelectorAll('.search-row-result-list-more').forEach(function (item) {
      item.classList.remove('search-row-result-list-more--active')
    })
  })


  // очистка формы по кнопку ecs
  if(!!searchOnSite) {
    document.addEventListener('keydown', escKeyDown);
  
    function escKeyDown(event) {
      if (event.keyCode === 27) {
        if(searchInput.value !== '') {
          clearInputValueEvent()
          document.querySelectorAll('.search-row-result-list-more').forEach(function (item) {
            item.classList.remove('search-row-result-list-more--active')
          })
        }
      }
    }
  }
});