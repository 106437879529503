import 'normalize.css'
import './scss/main.scss'
import './js/main'
import './js/validation'
import './js/components/hexagons'
import './js/components/dropdown'
import './js/components/btn-top'
import './js/components/cookies-message'
import './js/components/additions'
import './js/components/faq-accordeon'
import './js/components/anchor'
import './js/components/search'
import './js/components/search-data'
