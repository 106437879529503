import Inputmask from "inputmask";

document.addEventListener('DOMContentLoaded', function() {
  let inputsPhone = document.querySelectorAll('input[name="phone"]');
  inputsPhone.forEach(function(phone) {
    Inputmask("+7 (999) 999-99-99").mask(phone);
  });


  let inputsName = document.querySelectorAll('input[name="name"]');
	inputsName.forEach(function(input) {
		input.addEventListener('input', function(event) {
			let inputValue = event.target.value;
			let cleanedValue = inputValue.replace(/[^a-zA-Zа-яА-Я ]+/g, '');
			cleanedValue = cleanedValue.replace(/\s+/g, ' ');
			event.target.value = cleanedValue;
		});
  });

	function checkValidInputs(event) {
		const formNode = event.target.form

		let nameInput = formNode.querySelector('input[name="name"]')
		let nameVal = nameInput.value
		let nameValValid = nameVal.length >= 3

		let phoneInput = formNode.querySelector('input[name="phone"]')
		let phoneVal = phoneInput.value
		let phoneValValid = !(/_/.test(phoneVal))

		if (!nameValValid) {
			nameInput.classList.add('fc-input--unvalid')
		} else {
			nameInput.classList.remove('fc-input--unvalid')
		}

		if (!(phoneValValid && !!phoneVal)) {
			phoneInput.classList.add('fc-input--unvalid')
		} else {
			phoneInput.classList.remove('fc-input--unvalid')
		}
		
		if (nameValValid && (phoneValValid && !!phoneVal)) {
			formNode.querySelector('.fc-submit').disabled = false
		} else {
			formNode.querySelector('.fc-submit').disabled = true
		}

	}

	async function handleFormSubmit(event) {
		event.preventDefault()

    fillHiddenInputs(event.srcElement.id)

		const url = event.target.action
		const data = serializeForm(event.target)

		toggleLoader(event.target.id)
  	const { status } = await sendData(data, url)
		toggleLoader(event.target.id)

		if (status === 200) {
			onSuccess(event.target)

      // Закрытие модалки через 5 сек после успешной отправки формы
      if (document.querySelector('#' + event.srcElement.id).closest('.modal')) {
        setTimeout(function() {
          const clickEvent = new MouseEvent("click", {
            bubbles: true,
            cancelable: true,
            view: window
          });
        
          document.querySelector('#' + event.srcElement.id).closest('.modal').querySelector('.modal__cross').dispatchEvent(clickEvent);
        }, 5000);
      }
		} else {
			onError(event.target)
		}


	}

	function onSuccess(formNode) {
    formNode.querySelector('.fc-message').classList.remove('fc-message--error')
    formNode.querySelector('.fc-message').classList.add('fc-message--success')
    formNode.querySelector('.fc-message').textContent = 'Ваша заявка отправлена!'
	}

	function onError(formNode) {
    formNode.querySelector('.fc-message').classList.remove('fc-message--success')
    formNode.querySelector('.fc-message').classList.add('fc-message--error')
    formNode.querySelector('.fc-message').textContent = 'Не удалось отправить заявку, попробуйте позже.'
	}
	
	function toggleLoader(formId) {
		const loader = document.getElementById(formId).querySelector('.fc-loader')
		loader.classList.toggle('d-none')
	}

	async function sendData(data, url) {
		return await fetch(url, {
			method: 'POST',
			body: data,
		})
	}
	
	function serializeForm(formNode) {
		let formData = new FormData(formNode)
		// console.log(Array.from(formData.entries()))

		return formData
	}
	
	const formInLayout = document.getElementById('fc-form--layout')
  if (formInLayout) {
    formInLayout.addEventListener('submit', handleFormSubmit)
    formInLayout.addEventListener('input', checkValidInputs)
  }

	const formInModal = document.getElementById('fc-form--modal')
  if (formInModal) {
    formInModal.addEventListener('submit', handleFormSubmit)
    formInModal.addEventListener('input', checkValidInputs)
  }

	const formInSearch = document.getElementById('fc-form--search')
  if (formInSearch) {
    formInSearch.addEventListener('submit', handleFormSubmit)
    formInSearch.addEventListener('input', checkValidInputs)
  }
	
  // Modals
  let modalTriggers = document.querySelectorAll('button[data-modal]');
  let modal = document.querySelector('.modal');
  let buttonModalTriggers

  if (modalTriggers) {
    modalTriggers.forEach(element => {
      element.addEventListener('click', function (event) {
        event.preventDefault()
        document.querySelector('html').classList.add('no-scroll')
        modal.classList.add('is-visible')
        buttonModalTriggers = element
      })
    });
  }

  if (modal) {
    modal.addEventListener('click', function (event) {
  
      const form = this.querySelector('.fc-form')
      const formMessage = form.querySelector('.fc-message')
  
      if (event.target.classList.contains('icon-base--cross')
          || event.target.classList.contains('icon-base__use--scross')
          || event.target.classList.contains('modal__cross')
          || event.target.classList.contains('modal')) {
        event.preventDefault()
        this.classList.remove('is-visible')
        
        // очистка формы
        formMessage.textContent = ''
        formMessage.classList = ''
        formMessage.classList.add('fc-message')
        form.reset();
  
        // добавить на кнопку отправить disabled
  
        document.querySelector('html').classList.remove('no-scroll')
      }
    }) 
  }

  function fillHiddenInputs(form) {
    let additionsForm = document.querySelector('#' + form)
    let url = window.location.href

    if (form === 'fc-form--modal') {
      // Заполняю список добавленных 
      let additionsList = document.querySelector('.product-dops__list-list')
      if (!!additionsList) {
        var checkboxes = additionsList.querySelectorAll("input[type=checkbox]");
        var checkedCheckboxes = [];
  
        checkboxes.forEach(function(checkbox) {
          if (checkbox.checked) {
            let additionName = checkbox.getAttribute('data-name')
            let additionPrice = checkbox.getAttribute('data-price')
            let additionSubscription = checkbox.getAttribute('data-subscription')
  
            checkedCheckboxes.push('Название - ' + additionName + ', Подключение - ' + additionPrice + ', Обслуживание - ' + additionSubscription + ' |');
          }
        });
      }
  
      // Записываю имя продукта (тайтл на странице)
      const nameProductElement = document.querySelector('.page-title')
      let nameProduct = 'Без названия'
      if (!!nameProductElement) {
        nameProduct = nameProductElement.textContent.trim()
      }
  
      // Записываю тип продукта (категорию)
      const typeProductElement = document.querySelector('.product-content__top-item--active')
      let typeProduct = 'Без раздела'
      if (!!typeProductElement) {
        typeProduct = typeProductElement.textContent.trim()
      }
  
      // Если на странице есть блок допов, то записываю в поля product_info и additions данные по ним
      if (!!document.querySelector('.product-dops')) {
  
        const startSetupPrice = document.querySelector('.product-dops__prices-price--setup').getAttribute('data-start-price')
        let startSetupSubscription = 0
        if (!!document.querySelector('.product-dops__prices-price--subscription')) {
          startSetupSubscription = document.querySelector('.product-dops__prices-price--subscription').getAttribute('data-start-price')
        }
  
        additionsForm.querySelector('input[name="product_info"]').value = `Название продукта - ${nameProduct}, Раздел - ${typeProduct}, Цена - ${startSetupPrice}, Подключение - ${startSetupSubscription}`
        additionsForm.querySelector('input[name="additions"]').value = checkedCheckboxes
  
      } else {
        let startSetupPrice = 0
        let startSetupSubscription = 0
  
        if (buttonModalTriggers.classList.contains('btn--pz')) {
          nameProduct = 'Физическая охрана'
          typeProduct = buttonModalTriggers.closest('.pz-prices__item').querySelector('.pz-prices__item-title').textContent.trim()
          additionsForm.querySelector('input[name="product_info"]').value = `Название продукта - ${nameProduct}, Раздел - ${typeProduct}`
        } else if (buttonModalTriggers.classList.contains('index-tarif__btn')) {
          nameProduct = buttonModalTriggers.closest('.index-tarif').querySelector('.index-tarif__list-title').textContent.trim()
          typeProduct = buttonModalTriggers.closest('.index-tarif__list-item').querySelector('.index-tarif__list-title').textContent.trim()
          startSetupPrice = buttonModalTriggers.closest('.index-tarif__list-item').querySelector('.index-tarif__list-tarif-price--setup .price-item').textContent.trim()
          startSetupSubscription = buttonModalTriggers.closest('.index-tarif__list-item').querySelector('.index-tarif__list-tarif-price--subscription .price-item').textContent.trim()
          additionsForm.querySelector('input[name="product_info"]').value = `Название продукта - ${nameProduct}, Раздел - ${typeProduct}, Цена - ${startSetupPrice}, Подключение - ${startSetupSubscription}`
        } else {
          additionsForm.querySelector('input[name="product_info"]').value = `Название продукта - ${nameProduct}, Раздел - ${typeProduct}`
        }
      }

    }

    // Добавляю информацию про город, урл и длительность за полнения формы
    let additionsFormCity = document.querySelector('.dropdown--city .dropdown__choose').textContent.trim()
    let additionsFormCityId = 0
    let cities = document.querySelectorAll(".dropdown--city .dropdown__link")
    for (let i = 0; i < cities.length; i++) {
      let city = cities[i];

      if (city.textContent === additionsFormCity) {
        additionsFormCityId = city.getAttribute('data-id')
      }
    }
    additionsForm.querySelector('input[name="city"]').value = additionsFormCityId
    additionsForm.querySelector('input[name="url"]').value = url
    additionsForm.querySelector('input[name="duration"]').value = timeDuration(openingTime, new Date())
  }

  // защита от ботов
  const inputs = document.querySelectorAll("input");
  let isTimeExecuted = false;
  let openingTime = 0

  inputs.forEach(function(input) {
    input.addEventListener("input", function() {
      if (!isTimeExecuted) {
        // Ваш код, который должен выполниться только один раз
        openingTime = new Date();

        isTimeExecuted = true;
      }
    });
  });

  function timeDuration(t1, t2) {
    return ((t2 - t1) / 1000);
  }
});
